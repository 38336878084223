import {Controller} from "stimulus"
import * as shared from "controllers-shared"
import * as util from "wellness/util"

export default class extends Controller {

  connect() {
    this.updateSidebar()
  }

  updateSidebar() {
    function getCoords(el) {
      var element = el.getBoundingClientRect();
      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var clientTop = docEl.clientTop || body.clientTop || 0;
      var top = element.top + scrollTop - clientTop;
      return {
        top: Math.round(top),
        bottom: Math.round(top + element.height),
        left: Math.round(element.left),
        right: Math.round(element.left + element.width)
      };
    }

    function reposition() {
      var currentScrollPosition = window.scrollY;
      var sidebarCoords = getCoords(sidebar);
      var sidebarWrapperCoords = getCoords(sidebarWrapper);

      if (currentScrollPosition >= heroImageCoords.bottom - 110) {
        var overlapping = false;
        for (var i = 0; i < potentialCollisionEls.length; i++) {
          var comp = getCoords(potentialCollisionEls[i])
          if (sidebarWrapperCoords.bottom >= (comp.top - 40) &&
            sidebarWrapperCoords.top <= (comp.bottom + 40) &&
            sidebarWrapperCoords.right >= (comp.left - 20))
          {
            overlapping = true;
          }
        }

        if (overlapping) {
          if (shared.hasClass(sidebar, "fade-in")) {
            shared.removeClass(sidebar, "fade-in");
            shared.addClass(sidebar, "fade-out");
          }
        } else {
          shared.removeClass(sidebar, "hide");
          shared.addClass(sidebar, "fade-in");
          shared.removeClass(sidebar, "fade-out");
        }

        if (sidebarCoords.bottom >= contentCoords.bottom) {
          shared.addClass(sidebar, "bottom");
        }

        if (shared.hasClass(sidebar, "bottom") &&
          currentScrollPosition < contentCoords.bottom - sidebarHeight - headerHeight - 71) {
            shared.removeClass(sidebar, "bottom");
        }

        shared.addClass(sidebar, "scroll");
      } else {
        shared.removeClass(sidebar, "scroll");
      }
    }

    var body = document.body;
    var docEl = document.documentElement;
    var sidebar = this.element

    if (sidebar) {
      var quotes = util.toArray(document.getElementsByClassName("block-quote"));
      var fullImgs = util.toArray(document.getElementsByClassName("img-full"));
      var wideImgs = util.toArray(document.getElementsByClassName("img-wide"));
      var coachSessions = util.toArray(document.getElementsByClassName("__banzai-coach"));

      var potentialCollisionEls = quotes.concat(fullImgs, wideImgs, coachSessions)
      var sidebarWrapper = document.getElementsByClassName("sidebar__wrapper")[0];
      var header = document.getElementsByTagName("header")[0];
      var headerHeight = header.getBoundingClientRect().height;
      var sidebarHeight = sidebar.getBoundingClientRect().height;
      var contentCoords = getCoords(document.getElementsByClassName("resource__content")[0]);
      var heroImageCoords = getCoords(document.getElementsByClassName("hero-image")[0]);

      // Make sure it's visible if we reload and we're high enough on the page
      if (window.scrollY < heroImageCoords.bottom - 110) {
        shared.removeClass(sidebar, "hide")
      }
      setInterval(reposition, 50);
    }
  }

}
